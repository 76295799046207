import styled from "styled-components";
type Direction = "column" | "row";

type SectionHeaderProps = {
  direction?: Direction;
  justifyContent?: string;
  alignItems?: string;
  gap?: string;
};

type SectionBodyProps = {
  hasBackground?: boolean;
};

export const Main = styled.div`
  position: relative;
  width: 100%;
  padding: 0;
  box-sizing: border-box;
  border-radius: 0.8rem;
`;

export const Section = {
  Container: styled.div`
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 2cqw;
  `,
  Header: styled.div<SectionHeaderProps>`
    font-size: 1rem;
    line-height: 1.25rem;
    color: ${({ theme }) => theme.colors.gray[50]};
    font-weight: 500;
    display: flex;
    gap:${({ gap }) => gap || '0.5rem'};
    align-items: ${({ alignItems }) => alignItems || "center"};
    flex-direction: ${({ direction }) => direction};
    justify-content: ${({ justifyContent }) => justifyContent || "flex-start"};
    margin: 0.8rem 0 1.2rem 0;
    font-weight: 500;
  `,
  Body: styled.div<SectionBodyProps>`
    width: 100%;
    box-sizing: border-box;
    ${({ theme, hasBackground }) =>
      hasBackground &&
      `
        background-color:  ${theme.colors.gray[300]}; 
        padding: 1rem 0;
        `}
  `,
};

export const TrainingTimeChartContainer = styled.div`
  box-sizing: border-box;
  padding: 2rem 0.5rem;
  width: 100%;
  display: flex;
  place-items: center;
  gap: 1rem;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.gray[300]};

  @media only screen and (max-width: 1024px) {
    flex-direction: column;
  }
`;