import styled, { css } from "styled-components";

export const Main = styled.main`
  box-sizing: border-box;
  padding: 1.6rem 1rem 0 calc(16.5rem + 1rem);
  min-height: 100%;
  position: relative;

  @media (max-width: 1024px) {
    padding: calc(9.5rem) 1rem 0 1rem;
  }
`;

export const Header = styled.header``;

export const Back = styled.div`
  color: ${({ theme }) => theme.colors.gray[100]};
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;

  path {
    fill: ${({ theme }) => theme.colors.gray[100]};
    stroke: ${({ theme }) => theme.colors.gray[100]};
  }
`;

export const Title = styled.div`
  display: block;
  margin-bottom: 1rem;

  .detail-destak {
    color: ${({ theme }) => theme.colors.primary.warning[900]};
    grid-column: 2;
  }

  .detail-right {
    text-align: right;
    color: ${({ theme }) => theme.colors.gray[50]};
    opacity: 0.36;
  }
`;

export const TitleCard = styled.div<{ isMobile: boolean }>`
  display: grid;
  grid-template-columns: 86px 1fr;
  width: 100%;
  gap: 1rem;

  @media only screen and (max-width: 767px) {
    grid-template-columns: 56px 1fr;
  }
`;

export const TitleDetail = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  .destak-text {
    color: ${({ theme }) => theme.colors.primary.warning[900]};
  }

  .detail-right {
    text-align: right;
  }
`;

export const TitleImg = styled.img<{
  isRound: boolean,
  isMobile: boolean,
  isLoading: boolean,
}>`
  width: ${(props) => (props.isMobile ? "56px" : "86px")};
  height: ${(props) => (props.isMobile ? "56px" : "86px")};
  border-radius: ${(props) => (props.isRound ? "50%" : "0")};
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LeftCol = styled.div`
  flex: 0.6;
  margin-bottom: 1rem;

  @media (min-width: 768px) and (max-width: 1279px) {
    flex: 0.4;
  }

  @media (max-width: 768px) {
    flex: 1;
  }
`;

export const RightCol = styled.div`
  flex: 0.4;
  justify-content: flex-end;
  display: flex;

  @media (min-width: 768px) and (max-width: 1279px) {
    flex: 0.6;
  }

  @media (max-width: 767px) {
    flex: 1;
  }
`;

export const Content = styled.div`
  padding-top: 1rem;
`;

export const BreadcrumbContainer = styled.div`
  color: ${({ theme }) => theme.colors.gray[50]};
  font-size: 1.125rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  opacity: 0.36;
  padding-bottom: 1rem;
  cursor: pointer;
`;

type BreadcrumbLinkProps = {
  path?: string;
};
export const BreadcrumbLink = styled.span<BreadcrumbLinkProps>`
  cursor: ${({ path }) => (path ? "pointer" : "default")};
  ${({ theme, path }) => !path && `color: ${theme.colors.gray[50]}`};

  &::after {
    content: " / ";
  }

  &:last-child {
    &::after {
      content: "";
    }
  }
`;

export const PageLoaderContainer = styled.div`
  padding-left: 16.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  @media (max-width: 1024px) {
    padding-left: 0;
  }
  
`;
