import styled, { keyframes } from "styled-components";
type CardContainerProps = {
  loading?: boolean | string;
};

const pulse = keyframes`
  0% {
        opacity: 0.65;
    }
    50% {
        opacity:1 ;
    }
    100% {
        opacity: 0.65;
    }
`;

export const BenefitPage = styled.div`
  display: flex;
  gap: 1rem;

  @media (max-width: 1024px) {
    flex-direction: column;
    padding-bottom: 1rem;
  }
`;

export const Benefit = {
  Container: styled.div<CardContainerProps>`
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    background-color: ${({ theme }) => theme.colors.gray[300]};
    animation: ${({ loading }) => (loading || loading === "true" ? pulse : "none")} 1s infinite linear;
    overflow: hidden;
    border-radius: 0.6rem;
    pointer-events: none;
    height: 100%;
    width: 100%;

    ${({ loading }) =>
      loading &&
      `
            justify-content: center;
            align-items: center;
            display: flex !important;
            width: 100% !important;
        `}
  `,
  Title: styled.h2`
    display: flex;
    align-items: center;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.gray[100]};
    width: 100%;
    font-size: 1.125rem;
    line-height: 1.5rem;

    @media (max-width: 1024px) {
      line-height: 1rem;
      font-size: 0.875rem;
    }
  `,
  Value: styled.div`
    margin: 0;
    font-weight: 300;
    font-family: "Roboto Light", sans-serif;
    font-size: 3.5rem;
    text-align: center;

    @media (max-width: 1023px) {
      font-size: 2rem;
      padding-bottom: 0.8rem;
    }
  `,
  Small: styled.small`
    font-size: 1rem;
    display: block;
    width: 100%;
  `,
  Unit: styled.span`
    font-size: 2rem;
  `
};
