import { IconProps } from "../../types/icon";

const PizzaChart = ({ width = 20, height = 20, fill = "#F2F2F2" }: IconProps) => (
  <svg width={width} height={height} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M9 20C3.93 19.5 0 15.21 0 10C0 4.79 3.93 0.5 9 0V20ZM11.03 0V8.99H20C19.53 4.25 15.76 0.47 11.03 0ZM11.03 20V11.01H20C19.53 15.75 15.77 19.53 11.03 20Z'
      fill='#999999'
    />
  </svg>
);

export default PizzaChart;
