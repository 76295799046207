import { IconProps } from "../../types/icon";

const Cog = ({ width = 21, height = 21, fill = "#B0D8A3" }: IconProps) => (
  <svg width={width} height={height} viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M14.35 19L12.075 21.25C11.7864 21.5333 11.4351 21.675 11.0211 21.675C10.607 21.675 10.2584 21.5333 9.97502 21.25L7.6541 19H4.50002C4.08752 19 3.7344 18.8531 3.44065 18.5594C3.1469 18.2656 3.00002 17.9125 3.00002 17.5V14.3603L0.700024 12.05C0.416691 11.7614 0.275024 11.4101 0.275024 10.9961C0.275024 10.582 0.416691 10.2333 0.700024 9.94999L3.00002 7.63967V4.49999C3.00002 4.08749 3.1469 3.73437 3.44065 3.44062C3.7344 3.14687 4.08752 2.99999 4.50002 2.99999H7.6397L9.95002 0.699994C10.2429 0.416661 10.5995 0.274994 11.0197 0.274994C11.4399 0.274994 11.7917 0.424994 12.075 0.724994L14.35 2.99999H17.5C17.9125 2.99999 18.2656 3.14687 18.5594 3.44062C18.8531 3.73437 19 4.08749 19 4.49999V7.63967L21.3 9.94999C21.5834 10.2386 21.725 10.5899 21.725 11.0039C21.725 11.418 21.5834 11.7667 21.3 12.05L19 14.3603V17.5C19 17.9125 18.8531 18.2656 18.5594 18.5594C18.2656 18.8531 17.9125 19 17.5 19H14.35ZM11.025 20.175L13.7229 17.5H17.5V13.725L20.225 11L17.5 8.27499V4.49999H13.725L11.025 1.77499L8.27502 4.49999H4.50002V8.27499L1.77502 11L4.50002 13.725V17.5H8.25002L11.025 20.175ZM11 13.475L13.175 14.8C13.3084 14.8833 13.4417 14.875 13.575 14.775C13.7084 14.675 13.7584 14.5417 13.725 14.375L13.15 11.925L15.075 10.25C15.2084 10.1333 15.2459 9.99999 15.1875 9.84999C15.1292 9.69999 15.0167 9.61666 14.85 9.59999L12.325 9.39999L11.35 7.07499C11.2855 6.92499 11.1694 6.84999 11.0016 6.84999C10.8339 6.84999 10.7167 6.92499 10.65 7.07499L9.67502 9.39999L7.15002 9.59999C6.98336 9.61666 6.87086 9.69999 6.81252 9.84999C6.75419 9.99999 6.79169 10.1333 6.92502 10.25L8.85002 11.925L8.27502 14.375C8.24169 14.5417 8.29169 14.675 8.42502 14.775C8.55836 14.875 8.69169 14.8833 8.82502 14.8L11 13.475Z'
      fill='#F2F2F2'
    />
  </svg>
);

export default Cog;
