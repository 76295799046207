import moment from "moment";
import { useCallback, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { TrendingIcon } from "../../components/icons";
import { VRIntlProviderComponent } from "../../components/providers/intl-provider";
import Frame from "../../components/ui/frame";
import Page, { PageLoader } from "../../components/ui/page";
import { DashboardFiltersProvider, useDashboardFilters } from "../../context/dashboard-filters";
import { useFeatureToggles } from "../../context/feature-toggles";
import { useUser } from "../../context/user";
import { FiltersList } from "../../enums/dashboard";
import { Features } from "../../enums/features";
import { Roles } from "../../enums/user";
import useMediaQuery from "../../utils/hooks/use-media-query";
import MinutesSpent from "../shared/minutes-spent";
import DashboardCharts from "./charts/dashboard-charts";
import SessionChart from "./charts/session-chart";
import TopScenariosCompleted from "./charts/top-scenarios-completed";
import TopScenariosPlayed from "./charts/top-scenarios-played";
import SessionsList from "./components/session-list";
import TopStats from "./components/top-stats";
import { FiltersContainer, FullContainer, SeeAllStats, TopStatsTitleContainer } from "./dashboard.styles";
import DateFilter from "./filters/date";
import TraineesFilter from "./filters/trainees";

const localeFn = (target) => import(`./locale/${target.toLowerCase()}.json`);

const Filters = () => {
  const { state: { role } } = useUser();
  const [filterOpen, setFilterOpen] = useState(false);

  return <FiltersContainer>
    {
      role === Roles.Instructor && <TraineesFilter
        isOpen={FiltersList.TraineesList === filterOpen}
        handleOnClick={setFilterOpen}
      />
    }
    <DateFilter
      isOpen={FiltersList.DatePicker === filterOpen}
      handleOnClick={setFilterOpen}
    />
  </FiltersContainer>;
}

const TopStatsTitle = ({ visible, collapsed, onToggle }) => {
  return <TopStatsTitleContainer>
    <FormattedMessage id={"top-stats"} />
    {visible && <SeeAllStats onClick={onToggle}><FormattedMessage id={`dashboard:top-stats-${collapsed ? "see-all" : "see-less"}`} /></SeeAllStats>}
  </TopStatsTitleContainer>;
}

const Dashboard = () => {
  const intl = useIntl();
  const { state: user } = useUser();
  const { state: filters } = useDashboardFilters();
  const [viewAllTopStats, setViewAllTopStats] = useState(true);
  const { isFeatureActive } = useFeatureToggles();
  const params = useParams();
  const isDevice = useMediaQuery("(max-width: 1024px)");
  const { userId, from, to } = filters;

  const handleOnToggleViewAllTopStats = useCallback(() => {
    setViewAllTopStats(!viewAllTopStats);
  }, [viewAllTopStats, isDevice]);

  const hasSessionTimelineChart = (user.role === Roles.Trainee || (user.role === Roles.Instructor && params.userId)) && isFeatureActive(Features.SessionChart);
  const hasSessionList = isFeatureActive(Features.SessionList);
  const isTopStatsCollapsed = isDevice && !viewAllTopStats;

  return (
    <Page
      code="dashboard-page"
      title={intl.formatMessage({ id: "dashboard:title" })}
      description={intl.formatMessage({ id: `dashboard:description-${user.role.toLowerCase()}` })}
      rightContent={<Filters />}>

      <FullContainer>
        <Frame
          transparent
          title={
            <TopStatsTitle
              visible={isDevice}
              collapsed={isTopStatsCollapsed}
              onToggle={handleOnToggleViewAllTopStats} />}
          icon={<TrendingIcon />}>
          <TopStats max={isTopStatsCollapsed ? 2 : -1} />
        </Frame>
      </FullContainer>

      {hasSessionTimelineChart && <SessionChart />}
      {hasSessionList && <SessionsList />}

      <DashboardCharts />

      {!isFeatureActive(Features.HideMinutesSpentTraining) && <MinutesSpent
        userId={userId}
        from={from ? moment(from).format("yyyy-MM-DD") : null}
        to={to ? moment(to).format("yyyy-MM-DD") : null} />}

      {isFeatureActive(Features.Top5Scenarios) &&
        <FullContainer>
          <TopScenariosPlayed />
          <TopScenariosCompleted />
        </FullContainer>}

    </Page>
  );
};

const DashboardPage = () =>
  <VRIntlProviderComponent
    localeFn={localeFn}
    id="dashboard"
    fallback={<PageLoader />}>
    <DashboardFiltersProvider>
      <Dashboard />
    </DashboardFiltersProvider>
  </VRIntlProviderComponent>

export default DashboardPage;
