import { Area, AreaChart, CartesianGrid, Label, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import useMediaQuery from "../../../utils/hooks/use-media-query";

interface DataItem {
  value: number | string;
  name: string;
}

type Props = {
  hideY?: boolean;
  color?: string;
  data: DataItem[];
  referenceLine?: DataItem | null;
  interval: number;
  xAxisLabel?: string;
  yAxisLabel?: string;
  tooltipContent?: any;
};

const VRAreaChart = ({
  hideY = false,
  color = "#EE7127",
  data,
  referenceLine = null,
  xAxisLabel,
  yAxisLabel,
  tooltipContent: TooltipContent = null,
}: Props) => {
  const id = `area-gradient-${color}`;

  const isMobile = useMediaQuery("(max-width: 767px)");
  const margins = isMobile ? { top: 0, right: 0, bottom: -20, left: -40 } : { top: 30, right: 40, bottom: 30, left: 0 };

  if (!data) return null;

  return (
    <ResponsiveContainer width="100%" height={360} className="area-chart">
      <AreaChart data={data} margin={margins}>
        <defs>
          <linearGradient id={id} x1="0" y1="0" x2="0" y2="1">
            <stop offset="40%" stopColor={color} stopOpacity={0.8} />
            <stop offset="100%" stopColor={color} stopOpacity={0} />
          </linearGradient>
        </defs>
        <CartesianGrid color="white" strokeDasharray="5 5" opacity="0.1" />
        <XAxis dataKey="name" fontSize="0.8rem" fill="red" tick={{ fill: "#F2F2F2" }} interval={data.length - 2}>
          {xAxisLabel && !isMobile && <Label value={xAxisLabel.toUpperCase()} offset={0} position="insideBottom" fill="#F2F2F2" />}
        </XAxis>
        {!hideY && (
          <YAxis dataKey="value" axisLine={false} tickLine={false} fontSize="0.8rem" tick={{ fill: "#F2F2F2" }}>
            {yAxisLabel && <Label value={yAxisLabel.toUpperCase()} offset={0} position="insideLeft" fill="#F2F2F2" />}
          </YAxis>
        )}
        <Tooltip formatter={(label: string) => label + " %"} content={<TooltipContent />} />
        <Area
          activeDot={{ strokeWidth: "4px", stroke: color }}
          type="monotone"
          dataKey="value"
          stroke={color}
          strokeWidth="2px"
          fill={`url(#${id})`}
        />
        {referenceLine && (
          <ReferenceLine stroke="white" opacity="0.4" strokeDasharray="3 3" y={referenceLine.value} isFront>
            <Label value={referenceLine.name} fill={color} opacity="0.8" position="insideBottomLeft" />
          </ReferenceLine>
        )}
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default VRAreaChart;
