import { CardContainer, Card as CardContent } from "./index.styles";

interface SharedCardProps {
  title: string;
  value: string | number;
  prefix?: string;
  sufix?: string;
  loading?: boolean;
  position?: "start" | "center" | "end";
}

export function Card({ loading, title, value, prefix, sufix, position }: SharedCardProps) {
  return (
    <CardContainer loading={loading}>
      <CardContent.Title>{title}</CardContent.Title>
      <CardContent.ValueGroup position={position}>
        <CardContent.Value>{prefix}</CardContent.Value>
        <CardContent.Value>{value}</CardContent.Value>
        <CardContent.Value>{sufix}</CardContent.Value>
      </CardContent.ValueGroup>
    </CardContainer>
  );
}
