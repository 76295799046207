import { camelCase } from "lodash";
import client from "../../client";
const MILLION = 1000000;

export const getTimeSpentChartData = async () => {
  const { data } = await client.get(`/v1/Overview/time-spent-chart`);

  if (data.datasets.length) {
    const labelCode = data.label.code.toLowerCase();
    const entryValueCode = camelCase(data.value.code);
    let valueCode = "Duration";

    const datasetsVertical = data.datasets.map((entry, index) => {
      const newValue = { ...entry };
      newValue[valueCode] =
        Math.floor(entry[entryValueCode] / 1000 / 60);

      newValue[labelCode] = new Intl.DateTimeFormat("pt", {
        day: "2-digit",
        month: "2-digit"
      }).format(new Date(entry[labelCode]));

      return newValue;
    });
    data.value.code = valueCode;
    data.datasets = datasetsVertical;
  }

  return data;
};

export const getOverviewTopStats = async () => {
  const { data = [] } = await client.get(`/v1/Overview/top-stats`);

  const benefitItems = data.map((item) => {
    const isCurrencyType = item.displayMeasurementType === "Currency";

    if (isCurrencyType) {
      const dividedValue = item.value / MILLION;
      item.value = dividedValue
    } else {
      const withoutDecimal = Number(item.value.toFixed());

      item.value = new Intl.NumberFormat("en-GB", {
        minimumFractionDigits: 0,
        minimumFractionDigits: 0
      }).format(withoutDecimal);
    }

    const formatedItem = {
      title: item.description,
      value: isCurrencyType ? item.value.toFixed(2) : item.value,
      prefix: isCurrencyType ? item.unit : "",
      sufix: !isCurrencyType ? item.unit : isCurrencyType ? "m" : ""
    };

    return formatedItem;
  });
  return benefitItems;
};
