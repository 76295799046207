import styled, { keyframes } from "styled-components"
type CardContainerProps = {
  loading?: boolean | string
}

type CardValueGroup = {
  position?: "start" | "center" | "end"
}

const pulse = keyframes`
  0% {
        opacity: 0.65;
    }
    50% {
        opacity:1 ;
    }
    100% {
        opacity: 0.65;
    }
`

export const CardContainer = styled.div<CardContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 1rem;
  background-color: ${({ theme }) => theme.colors.gray[300]};
  animation: ${({ loading }) => (loading || loading === "true" ? pulse : "none")} 1s infinite linear;
  overflow: hidden;
  border-radius: 0.6rem;
  pointer-events: none;
  height: 100%;
  width: 100%;
  box-sizing: border-box;

  ${({ loading }) =>
    loading &&
    `
            justify-content: center;
            align-items: center;
            display: flex !important;
            width: 100% !important;
        `}
`

export const Card = {
  Title: styled.h2`
    display: flex;
    align-items: center;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.gray[100]};
    width: 100%;
    font-size: 1.125rem;
    line-height: 1.5rem;

    @media (max-width: 1024px) {
      line-height: 1rem;
      font-size: 0.875rem;
    }
  `,
  ValueGroup: styled.div<CardValueGroup>`
    display: flex;
    align-items: center;
    justify-content: ${({ position }) => position ? position : 'flex-start' };
  `,
  Value: styled.div`
    margin: 0;
    font-weight: 300;
    font-family: "Roboto Light", sans-serif;
    font-size: 3.5rem;
    text-align: center;

    @media (max-width: 1023px) {
      font-size: 2rem;
      padding-bottom: 0.8rem;
    }
  `,
  Small: styled.small`
    font-size: 1rem;
    display: block;
    width: 100%;
  `,
  Unit: styled.span`
    font-size: 2rem;
  `
}
