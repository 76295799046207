import { useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { VRIntlProviderComponent } from "../../components/providers/intl-provider";
import Button from "../../components/ui/button";
import Page, { PageLoader } from "../../components/ui/page";
import { SettingsProvider, useSettings } from "../../context/settings";
import { SET_EDIT_MODE } from "../../context/settings/reducer";
import Footer from "./components/footer";
import PersonalDetails from "./components/personal-details";
import UserProfile from "./components/user-profile";
import { Column, Container, Section } from "./settings.styles";

const localeFn = (target: string) => import(`./locale/${target.toLowerCase()}.json`);

type Props = {};

const Settings = (props: Props) => {

    const intl = useIntl();
    const { state: settings, dispatch } = useSettings();

    const handleOnClickSave = useCallback(() => { }, []);

    const handleOnEditClick = useCallback(() => {
        dispatch({ type: SET_EDIT_MODE, payload: true });
    }, []);

    return <Page
        code="settings-page"
        title={intl.formatMessage({ id: "settings:title" })}
        description={""}
        footer={<Footer onClickSave={handleOnClickSave} dirty={settings.dirty} />}>
        <Container>
            <Column>
                <Section.Container>
                    <Section.Title>
                        <FormattedMessage id="settings:user-profile" />
                    </Section.Title>
                    <UserProfile />
                </Section.Container>
            </Column>
            <Column>
                <Section.Container>
                    <Section.Title>
                        <FormattedMessage id="settings:personal-details" />
                        <Button size="small" onClick={handleOnEditClick}>
                            <FormattedMessage id="settings:edit" />
                        </Button>
                    </Section.Title>
                    <PersonalDetails />
                </Section.Container>
            </Column>
        </Container>
    </Page>
}

const SettingsPage = (props: Props) =>
    <VRIntlProviderComponent
        localeFn={localeFn}
        id="settings"
        fallback={<PageLoader />}>
        <SettingsProvider>
            <Settings {...props} />
        </SettingsProvider>
    </VRIntlProviderComponent>



export default SettingsPage;
