
import Text from "../../components/ui/text";
import UserBadge from "../user-badge";
import getNavigationItems from "./get-navigation-items";
import { Container, IconWrapper, Logo, Menu, MenuContainer, MenuItem, MenuLink, Nav, Wrapper } from "./navigation.styles";

const Navigation = () =>
  <Wrapper>
    <Container>
      <Nav>
        <Logo />
        <MenuContainer>
          <Menu>
            {getNavigationItems()
              .map(({ path, icon: Icon, label,code }) => {
                return <MenuItem key={code}>
                  <MenuLink to={path} isActive={(match, location) => {
                    if (path.split('/').filter((v) => v)?.[0] === location.pathname.split('/').filter((v) => v)?.[0]) {
                      return true;
                    }
                    return !!match;
                  }}>
                    <IconWrapper><Icon /></IconWrapper>
                    <Text>{label}</Text>
                  </MenuLink>
                </MenuItem>
              })}
          </Menu>
        </MenuContainer>
      </Nav>
      <UserBadge />
    </Container>
  </Wrapper>

export default Navigation;
