import {
  DashboardIcon,
  LockIcon,
  SupportIcon,
  UsersIcon
} from "../../components/icons";
import PizzaChart from "../../components/icons/pizza-chart";
import { useFeatureToggles } from "../../context/feature-toggles";
import { useUser } from "../../context/user";
import { Features } from "../../enums/features";
import { Pages } from "../../enums/pages";
import { Roles } from "../../enums/user";

interface SiteMap {
  childNodes: any[];
  code: string;
  description: string;
  order: number;
  title: string;
  url: string;
}

interface MenuItem {
  code: string;
  icon: any;
  order: number;
  description?: string;
  label: string;
  notAllowedTo?: Roles[];
  path: string;
  enabled: boolean;
}

const getNavigationItems = () => {
  const { state: user } = useUser();
  const { isFeatureActive } = useFeatureToggles();
  let siteMapStringify = window.localStorage.getItem("siteMap");

  let siteMap: SiteMap[] = siteMapStringify
    ? JSON.parse(siteMapStringify)
    : [];

  const menuItens: MenuItem[] = [
    {
      icon: PizzaChart,
      label: "Overview",
      path: Pages.Overview,
      enabled: true,
      code: "overview-page",
      order: 1
    },
    {
      icon: DashboardIcon,
      label: "Dashboard",
      path: Pages.DashboardAllTrainees,
      notAllowedTo: [Roles.Trainee],
      enabled: Roles.Trainee !== user.role,
      code: "individual-training-page",
      order: 2
    },
    {
      icon: LockIcon,
      label: "Authentication",
      path: Pages.Authentication,
      enabled: isFeatureActive(Features.AuthenticationPage),
      code: "authentication-page",
      order: 3
    },
    {
      icon: UsersIcon,
      label: "Trainees",
      path: isFeatureActive(Features.PendingInvites)
        ? Pages.TraineesActive
        : Pages.Trainees,
      enabled: Roles.Trainee !== user.role,
      code: "trainees-page",
      order: 4
    },
    {
      icon: SupportIcon,
      label: "Support",
      path: Pages.Support,
      enabled: true,
      code: "support-page",
      order: 5
    }
  ];

  return menuItens
    .map((menu) => {
      // TODO: remove this condition when the endpoint siteMap is enabled in all enviroment
      if (siteMap.length) {
        const siteMapItem = siteMap.find((item) => item.code === menu.code);
        menu.enabled = (siteMapItem && true) || false;

        if (siteMapItem && siteMapItem.code !== "individual-training-page") {
          menu.label = siteMapItem.title;
          menu.description = siteMapItem.description;
          menu.order = siteMapItem.order;
        }
      }

      return menu;
    })
    .filter((item) => item.enabled)
    .sort((a: MenuItem, b: MenuItem) => a.order - b.order);
};

export default getNavigationItems;
