import { FormattedMessage, useIntl } from "react-intl";
import Page from "../../components/ui/page";
import { Main, Section } from "./index.styles";
import Styled from "../../components/ui/styled";
import Text from "../../components/ui/text";
import Cog from "../../components/icons/cog";
import { BenefitStats } from "./components/Benefits";
import { useQuery } from "react-query";
import { getOverviewTopStats, getTimeSpentChartData } from "../../api/overview";
import CustomCharts from "../shared/custom-charts";
import { useEffect, useState } from "react";
import { CustomChartType } from "../../types/custom-charts";

export default function OverviewPage() {
  const intl = useIntl();
  const pageName = intl.formatMessage({ id: "overview:description" });
  const [chartData, setChartData] = useState<CustomChartType[]>([]);
  const [topStatsData, setTopStatsData] = useState<any[]>([]);

  const { isLoading, data } = useQuery<CustomChartType>(
    "getTimeSpentChartData",
    getTimeSpentChartData
  );

  const { isLoading: isLoadingTopStats, data: topStats } = useQuery<any[]>(
    "getOverviewTopStats",
    getOverviewTopStats
  );

  useEffect(() => {
    if (data) {
      setChartData(() => [data]);
    }
  }, [data]);

  useEffect(() => {
    if (topStats) {
      setTopStatsData(() => topStats);
    }
  }, [topStats]);

  return (
    <Page
      description={pageName}
      code="overview-page"
      title={intl.formatMessage({ id: "overview:title" })}
    >
      <Main>
        <Section.Container>
          <Section.Body hasBackground={false}>
            {!isLoading && <CustomCharts charts={chartData} />}
          </Section.Body>
        </Section.Container>

        <Section.Container>
          <Section.Header alignItems="flex-start" gap="1rem" direction="column">
            <Styled display="flex" alignItems="center">
              <Styled marginRight={"1rem"} display="flex" alignItems="center">
                <Cog width={20} height={20} />
              </Styled>
              <FormattedMessage id="overview:benefits" />
            </Styled>
            <Text color="gray[100]" variant="xsMedium">
              <FormattedMessage id="overview:lifetime" />
            </Text>
          </Section.Header>
          <Section.Body>
            {!isLoading && <BenefitStats benefitsItem={topStatsData} />}
          </Section.Body>
        </Section.Container>
      </Main>
    </Page>
  );
}
