import moment from "moment";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import { useTheme } from "styled-components";
import AreaChart from "../../../components/graphs/area";
import { useFeatureToggles } from "../../../context/feature-toggles";
import { Features } from "../../../enums/features";
import {
  CustomChartDataset,
  CustomChartType
} from "../../../types/custom-charts";
import useMediaQuery from "../../../utils/hooks/use-media-query";
import ChartTooltip from "../../shared/chart-tooltip/chart-tooltip";
import { BarYTick } from "./components/bar-tick";
import { BarTooltip } from "./components/bar-tooltip";
import {
  ChartsContainer,
  Column,
  Container,
  Description,
  Header,
  LineChartWrapper,
  Row,
  Wrapper
} from "./custom-chart.styles";

import { ReactNode } from "react";
import PieChart from "../../../components/graphs/pie";
import { ClockIcon } from "../../../components/icons";
import Styled from "../../../components/ui/styled";
import { ChartTypes } from "../../../enums/charts";
import { toKebab } from "../../../utils/string";
import useQueryParams from "../../../utils/use-query-params";
import AnchorPoint from "./components/anchor-point";
import LegendList from "./components/legend-list";
import { LineXTick } from "./components/line-tick";
import LineTooltip from "./components/line-tooltip";
import { NoData } from "./components/no-data";
import { LINES_COLORS, formatYTickText, getChartTicks } from "./utils";
import { camelCase } from "lodash";
import Text from "../../../components/ui/text";
import { TrainingTimeChartContainer } from "../../overview/index.styles";

type ChartType = CustomChartType & { isLast: boolean };

const Chart = ({
  chartType,
  code,
  baseLine,
  description,
  label,
  value,
  datasets,
  legend,
  isLast,
  ...props
}: ChartType) => {
  const intl = useIntl();
  const { isFeatureActive } = useFeatureToggles();
  const isMobile = useMediaQuery("(max-width: 767px)");
  const params: any = useQueryParams();
  const theme = useTheme();

  const fromTime = new Date(params.get("from")).getTime();
  const toTime = new Date(params.get("to")).getTime();

  const labelKey = label.code;
  const valueKey = value.code;
  let hasData = false;
  let chartData = [];

  if (datasets.length === 0 && isFeatureActive(Features.HideEmptyCharts))
    return null;

  switch (chartType.toLowerCase()) {
    case ChartTypes.HorizontalBar:
      const fixedData = datasets.map((entry, index) => ({
        ...entry,
        [value.code]: (() => {
          if (value.displayMeasurementType === "Minutes")
            return entry[value.code] / 1000 / 60;

          return entry[value.code];
        })()
      }));

      const leftGap: number = 70;
      const margins = isMobile
        ? { top: 0, right: 0, bottom: -20, left: leftGap }
        : { top: 30, right: 40, bottom: 30, left: leftGap };

      return (
        <ResponsiveContainer width="100%" height={fixedData.length * 90}>
          <BarChart
            data={fixedData}
            layout="vertical"
            barSize={8}
            margin={margins}
          >
            <CartesianGrid
              horizontal={false}
              strokeDasharray="10 10"
              stroke={theme.colors.gray[200]}
            />
            <XAxis
              type="number"
              axisLine={false}
              dataKey={value.code}
              dy={10}
              tickSize={0}
              tick={false}
              domain={[0, "dataMax"]}
            />
            <YAxis
              type="category"
              axisLine={false}
              dataKey={label.code}
              fill={theme.colors.gray[50]}
              textAnchor="end"
              interval={0}
              tickSize={0}
              dx={-10}
              tick={<BarYTick width={leftGap + 10} fill={theme.colors.white} />}
            />
            {legend && (
              <Legend
                fill={theme.colors.gray[100]}
                content={<LegendList legend={legend} />}
              />
            )}
            <Tooltip
              isAnimationActive={false}
              separator={": "}
              cursor={false}
              content={<BarTooltip value={value} />}
            />
            <Bar dataKey={value.code} fill={theme.colors.primary.yellow[400]} />
          </BarChart>
        </ResponsiveContainer>
      );

    case ChartTypes.VerticalBar:
      const labelCode = label.code.toLowerCase();
      let valueCode = value.code;

      const tickerFormatter = (tick: number) => {
        if (value.displayMeasurementType === "Minutes") {
          const formattedText = tick > 0 ? "mins" : "min";
          return `${tick}\n${formattedText}`;
        }

        return tick.toString();
      };

      return (
        <TrainingTimeChartContainer>
          <ResponsiveContainer height={350} width="100%">
            <BarChart data={datasets} margin={{ bottom: 0 }}>
              <XAxis
                axisLine={false}
                tickLine={false}
                dataKey={labelCode}
                fontSize={14}
                stroke="#fff"
              />
              <YAxis
                axisLine={false}
                textAnchor="middle"
                tickLine={false}
                stroke="#fff"
                tick={{ dx: 16 }}
              >
                <Label position="inside" value={"Minutes"} angle={-90} />
              </YAxis>

              <Tooltip
                isAnimationActive={false}
                separator={": "}
                cursor={false}
                content={<BarTooltip value="day" sufix="min" />}
              />
              <Bar
                dataKey={valueCode}
                barSize={48}
                fill="#EE7127"
                minPointSize={0}
              />
            </BarChart>
          </ResponsiveContainer>
        </TrainingTimeChartContainer>
      );

    case ChartTypes.Area:
      chartData =
        (datasets &&
          datasets.map((item: any) => {
            let value = item[valueKey];
            let name = item[labelKey];

            if (label.currentMeasurementType === "DateTime") {
              name = isMobile
                ? moment(name).format("D/MM")
                : moment(name).format("Do MMM YY");
            }

            return {
              name,
              value,
              labelKey,
              valueKey
            };
          })) ||
        [];

      hasData = chartData.length > 0;

      return (
        (hasData && (
          <AreaChart
            tooltipContent={ChartTooltip}
            interval={props?.xAxisInterval || 7}
            data={chartData}
            xAxisLabel={intl.formatMessage({
              id: labelKey,
              defaultMessage: label.description
            })}
          />
        )) || <NoData />
      );

    case ChartTypes.Line:
      const offset = 200;
      const fixedDataSet = datasets
        .filter(
          (item: CustomChartDataset) =>
            Object.keys(item).length > 1 &&
            Object.keys(item).includes("CapturedDate")
        )
        .map((item: CustomChartDataset) => ({
          ...item,
          CapturedDate:
            (item.CapturedDate && new Date(item.CapturedDate).getTime()) ||
            null,
          ...(baseLine ? { BaseLine: Number(baseLine) } : {})
        }));

      const keys = Object.keys(fixedDataSet[0])
        .filter((key: string) => key !== "CapturedDate")
        .sort((key: string) => (key === "BaseLine" ? -1 : 1));

      hasData = datasets.length > 0;

      const maxValue = value.maxDisplayValue || 0;

      return (
        (hasData && (
          <LineChartWrapper>
            <ResponsiveContainer className="custom-chart">
              <LineChart
                width={1000}
                data={fixedDataSet}
                margin={{ top: 10, right: 0, bottom: 40, left: offset }}
              >
                <CartesianGrid
                  stroke="transparent"
                  fill={`rgba(56, 56, 56, 0.25)`}
                />
                <XAxis
                  axisLine={false}
                  dy={20}
                  dataKey={label.code}
                  tickLine={{ stroke: theme.colors.gray[200] }}
                  tickSize={15}
                  tick={
                    <LineXTick label={label} fill={theme.colors.gray[50]} />
                  }
                  ticks={getChartTicks(fromTime, toTime)}
                  type="number"
                  domain={[fromTime, toTime]}
                >
                  <Label
                    value={label.description}
                    position="bottom"
                    offset={25}
                    fill={theme.colors.gray[50]}
                  />
                </XAxis>
                <YAxis
                  allowDataOverflow
                  className="y-axis"
                  axisLine={false}
                  textAnchor="end"
                  mirror={true}
                  dx={-45}
                  type="number"
                  tickLine={{ stroke: theme.colors.gray[200] }}
                  tickSize={15}
                  tick={{ fill: theme.colors.gray[50] }}
                  tickFormatter={formatYTickText}
                  domain={([dataMin, dataMax]) => [
                    dataMin < 0 ? dataMin - 1 : 0,
                    (maxValue && (maxValue < dataMax ? dataMax : maxValue)) ||
                      dataMax
                  ]}
                >
                  <Label
                    textBreakAll={false}
                    width={offset * 0.5}
                    value={value.description}
                    position="insideLeft"
                    offset={-(offset * 0.5) - 30}
                    fill={theme.colors.gray[50]}
                  />
                </YAxis>
                <Tooltip
                  content={
                    <LineTooltip startTime={fromTime} data={{ label, value }} />
                  }
                />
                {keys.map((key: string, index: number) => (
                  <Line
                    key={key}
                    type="monotone"
                    stroke={LINES_COLORS[index]}
                    dataKey={key}
                    dot={false}
                    connectNulls={true}
                  />
                ))}
              </LineChart>
            </ResponsiveContainer>
          </LineChartWrapper>
        )) || <NoData />
      );

    case ChartTypes.Pie:
      hasData = datasets.length > 0;

      return (
        (hasData && (
          <PieChart
            legend={legend}
            data={datasets}
            label={label}
            value={value}
          />
        )) || <NoData />
      );

    case ChartTypes.AnchorPoints:
      return (
        <AnchorPoint isLast={isLast} title={description} {...datasets[0]} />
      );
    default:
      return null;
  }
};

type ChartSection = {
  section: string;
  order: number;
  charts: CustomChartType[];
};

const SectionHeader = ({ section }: { section: string }) => {
  switch (section) {
    case "anchor-points":
      return (
        <Header>
          <Styled marginRight="0.7rem">
            <ClockIcon />
          </Styled>
          <FormattedMessage id="anchor-points" defaultMessage="Anchor points" />
        </Header>
      );
    default:
      return null;
  }
};

const ChartDescription = ({
  section,
  children
}: {
  section: string;
  children: ReactNode;
}) => {
  if (section === "anchor-points") return null;

  return <Description>{children}</Description>;
};

const ChartSubtitle = ({ chart }: { chart: CustomChartType }) => {
  switch (chart.chartType.toLowerCase()) {
    case ChartTypes.VerticalBar:
      const subtitleId = chart.code + "-subtitle";
      return (
        <>
          <br />
          <Text color="gray[100]" variant="xsMedium">
            <FormattedMessage id={subtitleId} />
          </Text>
        </>
      );

    default:
      return null;
  }
};

const CustomCharts = ({ charts }: { charts: CustomChartType[] }) => {
  const { isFeatureActive } = useFeatureToggles();

  const chartsGrouped = charts
    .reduce((acc: ChartSection[], chart: CustomChartType) => {
      const { gridView } = chart;
      let section = (gridView && toKebab(gridView.groupSection)) || "misc";

      const order = gridView ? gridView.groupOrder : Infinity;
      const index = acc.findIndex((el: ChartSection) => el.section === section);

      if (index < 0) {
        acc.push({ section, order, charts: [chart] });
        return acc;
      }

      acc[index].charts.push(chart);
      return acc;
    }, [])
    .sort((a: ChartSection, b: ChartSection) => {
      if (a.section === "anchor-points" || b.section === "anchor-points")
        return 1;
      return a.order > b.order ? 1 : -1;
    })
    .map(({ charts, ...otherProps }: ChartSection) => ({
      ...otherProps,
      charts: charts.reduce(
        (acc: CustomChartType[][], chart: CustomChartType) => {
          const { gridView } = chart;
          const column = gridView ? gridView.columnPosition : 0;
          const row = gridView ? gridView.rowPosition : 0;

          if (!gridView) {
            acc.push([chart]);
            return acc;
          }

          if (!acc[row]) acc[row] = [];
          acc[row][column] = chart;

          return acc;
        },
        []
      )
    }));

  return (
    <ChartsContainer>
      {chartsGrouped.map(
        ({
          charts,
          section
        }: {
          charts: CustomChartType[][];
          section: string;
        }) => (
          <Container key={`container-for-${section}`}>
            <SectionHeader section={section} />
            {charts.map((row: CustomChartType[], rowIndex: number) => (
              <Row key={`row-${rowIndex}-for-${section}`}>
                {row.map((chart: CustomChartType) => {
                  const hasData = chart.datasets.length > 0;
                  const isLast = rowIndex === charts.length - 1;
                  const isFirst = rowIndex === 0;
                  if (!hasData && isFeatureActive(Features.HideEmptyCharts))
                    return null;

                  return (
                    <Column empty={!hasData} style={{ flex: 1 / row.length }}>
                      <ChartDescription section={section}>
                        <Styled marginRight="0.7rem">
                          <ClockIcon />
                        </Styled>
                        {chart.description}
                        <ChartSubtitle chart={chart} />
                      </ChartDescription>
                      <Wrapper
                        bottomGap={section !== "anchor-points" || isLast}
                        borderTopRadius={isFirst}
                        borderBottomRadius={isLast}
                      >
                        {hasData ? (
                          <Chart {...chart} isLast={isLast} />
                        ) : (
                          <Styled padding="1rem 0" textAlign="center">
                            <NoData />
                          </Styled>
                        )}
                      </Wrapper>
                    </Column>
                  );
                })}
              </Row>
            ))}
          </Container>
        )
      )}
    </ChartsContainer>
  );
};

export default CustomCharts;
