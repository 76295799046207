import { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useQuery } from "react-query";
import { useInterval } from "react-use";
import { useTheme } from "styled-components";
import { requestPassCode } from "../../api/user";
import { VRIntlProviderComponent } from "../../components/providers/intl-provider";
import Loader from "../../components/ui/loader";
import Page, { PageLoader } from "../../components/ui/page";
import Text from "../../components/ui/text";
import { useUser } from "../../context/user";
import { pad } from "../../utils/time";
import { Container, Footer, LoaderContainer, TimeLeft } from "./authentication.styles";
import PassCode from "./components/passcode";

const localeFn = (target: string) => import(`./locale/${target.toLowerCase()}.json`);

const formatExpirationTimeLeft = (duration: number) => {
  let seconds: number = Math.floor((duration / 1000) % 60),
    minutes: number = Math.floor((duration / (1000 * 60)) % 60),
    hours: number = Math.floor((duration / (1000 * 60 * 60)) % 24);

  return `${hours >= 1 ? `${pad(hours)}h` : ""}${`${pad(minutes)}m`}${`${pad(seconds)}s`}`;
};

const Authentication = () => {
  const intl = useIntl();
  const { state: user } = useUser();
  const [timeLeft, setTimeLeft] = useState<number>(-1);
  const theme = useTheme();

  const { data, isFetching, isLoading, refetch } = useQuery(["request-passcode", user.id], () => requestPassCode(user.id), {
    refetchOnWindowFocus: false,
    enabled: false,
    suspense: false,
    useErrorBoundary: true,
  });

  const calcTimeLeft = useCallback(
    () =>
      setTimeLeft(() => {
        if (!data) return -1;
        const now: Date = new Date();
        const expirationDate: Date = new Date(data.expiresAt);
        expirationDate.setHours(expirationDate.getHours() - expirationDate.getTimezoneOffset() / 60);
        const result: number = expirationDate.getTime() - now.getTime();
        return result;
      }),
    [data]
  );

  useInterval(calcTimeLeft, 1000);
  const enabled = timeLeft > 0;

  useEffect(() => {
    if (!enabled && !isFetching) {
      refetch();
    }
  }, [enabled]);

  useEffect(() => {
    if (data && data.expiresAt) {
      calcTimeLeft();
    }
  }, [data]);

  return (
    <Page code="authentication-page" title={intl.formatMessage({ id: "auth:title" })} description={intl.formatMessage({ id: "auth:description" })}>
      {isLoading || isFetching ? (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      ) : (
        <>
          <Container>
            <PassCode value={(enabled && data?.otpCode) || "    "} />
          </Container>
          {enabled && (
            <Footer>
              <FormattedMessage id="auth:active-for" />
              <TimeLeft>
                <Text variant="extraLarge2">{formatExpirationTimeLeft(Math.ceil(timeLeft / 1000) * 1000)}</Text>
              </TimeLeft>
            </Footer>
          )}
        </>
      )}
    </Page>
  );
};

const AuthenticationPage = () => (
  <VRIntlProviderComponent localeFn={localeFn} id="authentication" fallback={<PageLoader />}>
    <Authentication />
  </VRIntlProviderComponent>
);

export default AuthenticationPage;
